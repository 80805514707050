import api from "./api"

export const getTokenStatusHistory = async (tokenId: string, accessToken: string) =>{
    var response=await api().get(
        '/api/v1/tokens/'+tokenId+'/status/history', 
        {headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer '+accessToken
          },
        }
    )
    return response.data
}

export const getTokenTransactions = async (tokenId: string, accessToken: string) =>{
    var response=await api().get(
        '/api/v1/tokens/'+tokenId+'/transactions', 
        {headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer '+accessToken
          },
        }
    )
    return response.data
}
